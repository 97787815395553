import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import portfolioData from "../../../data/PortfolioData.json";


const useStyles = makeStyles(styles);

function PortfolioItem() {
  const classes = useStyles();
  const imageClasses = classNames(
  classes.imgFluid
);
return portfolioData.map((item) => (
  <GridItem xs={12} sm={12} md={4} p key={item.id} item={item}>
      <Card plain>
      <h4 className={classes.cardTitle}>
      {item.title}<br/>
      <small className={classes.smallTitle}>{item.subtitle}</small>
      </h4>
      <GridItem xs={10} className={classes.itemGrid}>
          <img src={item.img} alt={item.title} className={imageClasses} href={item.url} target="_blank" />
      </GridItem>
      <CardBody>
          <p className={classes.description}>{item.description1}</p>
          <p className={classes.description}>{item.description2}</p></CardBody>
      <CardFooter className={classes.justifyCenter}>
      <Button color="primary" href={item.url} target="_blank">{item.buttonText}</Button>
      </CardFooter>
      </Card>
  </GridItem>
))
}

export default function SectionSkills() {
  const classes = useStyles();

  return (
    <div className={classes.section} id="portfolio">
      <h2 className={classes.title}>Portfolio of previous projects</h2>
      <div>
        <GridContainer>
          <PortfolioItem />
        </GridContainer>
      </div>
    </div>
  );
}
