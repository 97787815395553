/*eslint-disable*/
import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";


// core components
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Button
          href="#about"
          color="transparent"
          className={classes.navLink}
        >About
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="#skills"
          color="transparent"
          className={classes.navLink}
        >Skills
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="#portfolio"
          color="transparent"
          className={classes.navLink}
        >Portfolio
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="#code"
          color="transparent"
          className={classes.navLink}
        >Code
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="visit-linkedin"
          title="Visit LinkedIn Profile"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            href="https://www.linkedin.com/in/greenwooddoug/"
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-linkedin"} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="visit-github"
          title="Visit Github profile"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://github.com/douggreenwoodlm24"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-github"} />
          </Button>
        </Tooltip>
      </ListItem>
    </List>
  );
}
