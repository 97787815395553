import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Quote from "components/Typography/Quote.js";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function SectionAbout(props) {
  const classes = useStyles();


  return (
    <div className={classes.sections} id="about">
      <div className={classes.container}>
        <div className={classes.title}>
          <h2>About me</h2>
        </div>
        <div id="buttons">
          <GridContainer justify="center">
            <GridItem xs={12} sm={8}>
            <div className={classes.typo}>
              <h4 className={classes.title}>Effective web design is judged by the users. A site should always look great but its not just about how good it looks, but how easy it is to use.</h4>
              <p>
              I believe in the importance of good planning and understanding what visitors want. Translating this via wireframes into good code, I always try to deliver a high quality user experience – mobile-friendly, accessible, fast, navigable and enjoyable to browse.</p>
              <p>I've spent the last 8 years as a front-end web developer at two medium-sized web design companies, which means I'm used to working on multiple projects simultaneously and delivering (on schedule) web applications that fully meet the needs of their clients and visitors.
              </p>
            </div>
            </GridItem>
            <GridItem xs={12} sm={4}>
            <div className={classes.typo}>
              <Quote
                text="&quot;Design is not just what it looks &amp; feels like. Design is how it works.&quot;"
                author=" Steve Jobs"
              />
            </div>
            </GridItem>
          </GridContainer>
          <GridContainer justify="left">
            <GridItem xs={12} sm={3} md={6}>
            <div className={classes.typo}>
              <h4 className={classes.title}>Experience</h4>
              <p>
              Over 15 years creating, developing and managing web sites &amp; online systems, from small, media-rich promotions to large-scale site re-designs.
              </p>
            </div>
            </GridItem>
            <GridItem xs={12} sm={3} md={6}>
            <div className={classes.typo}>
              <h4 className={classes.title}>Career</h4>
              <p>
              In my career I've been a front-end web developer at the McGraw-Hill Publishing Company, Avid Technology, PJ Media, Brandpath and Feedback Ferret.</p>
            </div>
            </GridItem>
            <GridItem xs={12} sm={3} md={6}>
            <div className={classes.typo}>
              <h4 className={classes.title}>Team-player</h4>
              <p>
              I'm a conscientious, organised and meticulous worker, a team player with good communication skills, excellent at problem-solving and dedicated to achieving project goals.</p>
            </div>
            </GridItem>
            <GridItem xs={12} sm={3} md={6}>
            <div className={classes.typo}>
              <h4 className={classes.title}>Agile</h4>
              <p>I can successfully manage multiple web projects simultaneously - from inception and planning, through on-schedule delivery to analysis of their success and long-term strategy recommendations.</p>
            </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
