import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import team1 from "assets/img/photo-code.jpg";
import team2 from "assets/img/photo-wireframe.jpg";
import team3 from "assets/img/photo-keyboard.jpg";

const useStyles = makeStyles(styles);

export default function SectionSkills() {
  const classes = useStyles();
  const imageClasses = classNames(classes.imgRoundedCircle, classes.imgFluid);
  return (
    <div className={classes.section} id="skills">
      <h2 className={classes.title}>Skills &amp; Knowledge</h2>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={team1} alt="Code" className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>Coding and Development</h4>
              <CardBody>
                <p className={classes.description}>HTML5</p>
                <p className={classes.description}>CSS3 / Sass / SCSS</p>
                <p className={classes.description}>JavaScript / ES6 / jQuery</p>
                <p className={classes.description}>
                  Vue.js / Vue CLI / Vuetify / Vuex / i18n
                </p>
                <p className={classes.description}>
                  React / create-react-app / Redux / Material UI
                </p>
                <p className={classes.description}>
                  REST APIs / Postman / Firebase / AWS
                </p>
                <p className={classes.description}>Node.js / NPM</p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={team2} alt="Planning" className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>UI / Planning</h4>
              <CardBody>
                <p className={classes.description}>
                  Wireframing (Balsamiq &amp; Adobe XD)
                </p>
                <p className={classes.description}>
                  Usability and User Experience
                </p>
                <p className={classes.description}>Information Architecture</p>
                <p className={classes.description}>W3C Standards</p>
                <p className={classes.description}>
                  Accessibility (WAI/ARIA, WCAG)
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={team3} alt="Other" className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>Other</h4>
              <CardBody>
                <p className={classes.description}>Agile (Jira, Redmine)</p>
                <p className={classes.description}>Photoshop / GIMP</p>
                <p className={classes.description}>Google Analytics</p>
                <p className={classes.description}>
                  CMS (RedDot/OpenText, Documentum)
                </p>
                <p className={classes.description}>Git / Gitbash / Github</p>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
