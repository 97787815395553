import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/typographyStyle.js";

import codeData from "../../../data/CodeData.json";

const useStyles = makeStyles(styles);

function RenderLinks(props){
  let url = props.url;
  let github = props.github;
  if (url !== "") {
    return <span><a href={url} rel="noopener noreferrer" target="_blank">View demo</a> | <a href={github} rel="noopener noreferrer" target="_blank">View files on Github</a></span>;
  } else {
    return <span><a href={github} rel="noopener noreferrer" target="_blank">View files on Github</a></span>
  }
}

function CodeItem(props) {
  const classes = useStyles();
return codeData.map((item) => (
  <div key={item.id}>
      <h4 className={classes.title}>
      {item.title}
      </h4>
      <p>{item.description}<br />
      <RenderLinks url={item.url} github={item.github} /></p>
      </div>
))
}

export default function SectionCode() {
  const classes = useStyles();

  return (
    <div className={classes.section} id="code">
      <div className={classes.container}>
      <h2 className={classes.title}>Code Examples</h2>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <h4>A few experiments and exercises I've done with React, Vue.js, JavaScript and Angular.</h4>
          </GridItem>
          <GridItem xs={12} sm={12} md={8}>
            <CodeItem />
          </GridItem>
        </GridContainer>
      </div>
      </div>
    </div>
  );
}
